<template lang="pug">
  Section.cy-basic-price-section(
    required
    :class="{ 'invalid-section': object.$error }"
    :tooltip="$t('plan_settings.basic_settings.sections.basic_price.tooltip')"
    :title="$t('plan_settings.basic_settings.sections.basic_price.section_name')"
  )
    .basic-price-forms
      .basic-price-form(
        v-if="object.optional"
      )
        PlanStatusBadge.m-b-10(:export-status="optionalExportStatus")
        BasicPriceForm.optional(
          :title="$t('plan_settings.basic_settings.sections.basic_price.insurance_optional')"
          :value="object.optional"
          :applied-changes="appliedChanges.optional"
        )
      .basic-price-form(
        v-if="object.included"
      )
        PlanStatusBadge.m-b-10(:export-status="includedExportStatus")
        BasicPriceForm.included(
          :title="$t('plan_settings.basic_settings.sections.basic_price.insurance_included')"
          :value="object.included"
          :applied-changes="appliedChanges.included"
        )
</template>

<script>
  export default {
    components: {
      Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section"),
      BasicPriceForm: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Shared/BasicPriceForm"),
      PlanStatusBadge: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/PlanStatusBadge")
    },

    props: {
      object: Object,
      appliedChanges: {
        type: Object,
        default: () => ({ optional: {}, included: {} })
      },
      optionalExportStatus: {
        type: String,
        default: undefined
      },
      includedExportStatus: {
        type: String,
        default: undefined
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .basic-price-forms
    align-items: flex-start
    display: flex
    justify-content: space-between
    width: 100%

    .basic-price-form
      width: 50%

      .optional,
      .included
        width: 100%
</style>
